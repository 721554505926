import { ApolloCacheSchema } from './schema/apolloCache';
import { OrderEventsSchema } from './schema/orderEvents';
import {
  addRxPlugin,
  createRxDatabase,
  RxDatabase,
  RxError,
  addPouchPlugin,
  getRxStoragePouch,
} from 'rxdb';
import 'react-native-get-random-values';
import SQLite from 'react-native-sqlite-2';
import SQLiteAdapterFactory from 'pouchdb-adapter-react-native-sqlite';
import { DATABASE_NAME } from '../constants';
import { Platform } from 'react-native';
import { RxDBQueryBuilderPlugin } from 'rxdb/plugins/query-builder';

export const COLLECTIONS = {
  apollo_cache: 'apollo_cache',
  order_events: 'order_events',
};

/**
 * Database class
 * Creates RxDB instance
 */
class Database {
  private static instance: RxDatabase;

  // Adapter names
  static adapters: {
    [key in typeof Platform.OS]?: string;
  } = {
    web: 'idb',
    android: 'react-native-sqlite',
    ios: 'react-native-sqlite',
  };

  /**
   * Creates database instance
   *
   * @returns Promise<RxDatabase>
   */
  private static async _createDatabase(): Promise<RxDatabase | undefined> {
    // Attaching adapter plugins
    if (Platform.OS === 'web') {
      addPouchPlugin(require('pouchdb-adapter-idb'));
    } else {
      const SQLiteAdapter = SQLiteAdapterFactory(SQLite);
      addPouchPlugin(SQLiteAdapter);
    }
    addRxPlugin(RxDBQueryBuilderPlugin);

    if (!this.adapters[Platform.OS]) return;

    this.instance = await createRxDatabase({
      name: DATABASE_NAME,
      storage: getRxStoragePouch(this.adapters[Platform.OS]),
      multiInstance: false,
    });
    try {
      await this.instance.addCollections({
        [COLLECTIONS.apollo_cache]: {
          schema: ApolloCacheSchema,
          instanceCreationOptions: {
            // clear document's history
            auto_compaction: true,
          },
        },
        [COLLECTIONS.order_events]: {
          schema: OrderEventsSchema,
          instanceCreationOptions: {
            // clear document's history
            auto_compaction: true,
          },
        },
      });
    } catch (error) {
      // Handle schema conflict issues
      if ((error as RxError).code === 'DB6') {
        await this.instance.remove();
        return await this._createDatabase();
      }
    }

    return this.instance;
  }

  /**
   * Returns singleton instance of database
   *
   * @returns RxDatabase
   */
  static async getInstance() {
    if (!this.instance) {
      await this._createDatabase();
    }
    return this.instance;
  }

  /**
   * Initializes database instance
   *
   * @returns RxDatabase
   */
  static async init() {
    return await this.getInstance();
  }
}

export default Database;
